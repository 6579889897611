<template>
  <div>
    <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-single-02"></i> Pertanyaan Umum
      </h6>
    </h1>

    <base-header class="pb-6">
      <b-row>
        <b-col xl="11" md="11"> </b-col>
      </b-row>
    </base-header>

    <b-container v-if="isLoading">
      <div>
        <H1 class="text-center" style="margin-top: 200px;">LOADING ... </H1>
      </div>
    </b-container>

    <b-container fluid class="mt--6" v-else>
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <b-row>
                <b-col lg="6" cols="7">
                  <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
                </b-col>
                <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end" v-if="log_as == 'admin'">
                  <base-button
                    size="md"
                    type="primary"
                    @click="handleAdd()"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    Tambah Pertanyaan
                  </base-button>
                </b-col>
              </b-row>
            </template>
            <!-- <PublikasiTable /> -->
            <el-table
              :data="listData"
              row-key="id"
              header-row-class-name="thead-light"
            >
            <el-table-column label="No" type="index" width="100" />

              <el-table-column min-width="180px" label="Pertanyaan">
                <div slot-scope="action">
                  <span v-html="action.row.question"></span>
                </div>
              </el-table-column>

              <el-table-column min-width="300px" label="Jawaban">
                <div slot-scope="action">
                  <span v-html="action.row.answer"></span>
                </div>
              </el-table-column>

              <el-table-column min-width="100px" label="Kategori">
                <div slot-scope="action">
                  {{ action.row.faq_category_name }}
                </div>
              </el-table-column>


              <el-table-column min-width="180px" label="Aksi" v-if="log_as == 'admin'">
                <div slot-scope="action">
                  <base-button type="warning" size="sm" icon @click="handleUpdate(action.row)">
                    UBAH
                  </base-button>
                  <base-button type="danger" size="sm" icon @click="doDelete(action.row)">
                    HAPUS
                  </base-button>
                </div>
              </el-table-column>
            </el-table>

            <div class="py-3">

                <el-select
                  class="select-primary pagination-select mr-3"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                  @change="changeSizePage()"
                  >
                  <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                  >
                  </el-option>
                </el-select>

                <small class="card-category">
                Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                </small>
            </div>

            <div style="display: flex;justify-content: flex-end;">
              <base-pagination
                  class="pagination-no-border"
                  :current="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="pagination.total"
                  @change="paginationChanged($event)"
              >
              </base-pagination>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    

    <!-- Tambah Publikasi -->
    <Modal
      :show="showAddPublicationModal"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="showAddPublicationModal = false"
      size="lg"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h4 class="mdl-title">Tambah Pertanyaan</h4>
        </div>
      </template>
      <div class="px-4 border-top pt-4">
        <div class="form-group row">
          <label class="col-lg-12 col-form-label form-control-label">Kategori Pertanyaan</label>
          <div class="col-lg-12">
            <base-input class="m-0">
                <el-select filterable placeholder="Jenis Industri" v-model="categoryQuestionInput">
                  <el-option
                    v-for="option in provideSelect.question"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  ></el-option>
                </el-select>
              </base-input>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-12">
            <label class="col-form-label form-control-label">Pertanyaan</label>
            <div>
              <html-editor v-model="questionInput"></html-editor>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-12">
            <label class="col-form-label form-control-label">Jawaban</label>
            <html-editor v-model="answerInput"></html-editor>
          </div>
        </div>
        
        <div class="form-group row">
          <button class="btn btn-primary" @click="doAdd">Simpan</button>
        </div>
      </div>
    </Modal>

    <!-- Edit Publikasi -->
    <Modal
      :show="showEditPublicationModal"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="showEditPublicationModal = false"
      size="lg"
    >
    <template v-slot:header>
        <div class="modal-title">
          <h4 class="mdl-title">Edit Pertanyaan</h4>
        </div>
      </template>
      <div class="px-4 border-top pt-4">
        <div class="form-group row">
          <label class="col-lg-12 col-form-label form-control-label">Kategori Pertanyaan</label>
          <div class="col-lg-12">
            <base-input class="m-0 mr-4">
                <el-select filterable placeholder="Jenis Industri" v-model="editQuestionCategory">
                  <el-option
                    v-for="option in provideSelect.question"
                    :key="option.id"
                    :label="option.name"
                    :value="option.id"
                  ></el-option>
                </el-select>
              </base-input>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-12">
            <label class="col-form-label form-control-label">Pertanyaan</label>
            <div>
              <html-editor v-model="editquestionInput"></html-editor>
            </div>
          </div>
        </div>
        
        <div class="form-group row">
          <div class="col-lg-12">
            <label class="col-form-label form-control-label">Jawaban</label>
            <div>
              <html-editor v-model="editanswerInput"></html-editor>
            </div>
          </div>
        </div>


        
        <div class="form-group row">
          <button class="btn btn-primary" @click="doEdit">Update</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from "../../../api/base_url";
import {Table, TableColumn, Select, Option } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import { BasePagination } from "@/components";
import clientPaginationMixin from "../../Tables/PaginatedTables/clientPaginationMixin"
import PublikasiTable from "../../Tables/AdminTable/Publikasi.vue";
import Modal from "@/components/Modal.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";


export default {
  mixins: [clientPaginationMixin],
  components: {
    BaseHeader,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PublikasiTable,
    Modal,
    HtmlEditor,

  },
  mounted() {
    this.getData()
    this.getTypeQuestion()
    this.log_as = localStorage.getItem('as')
  },
  data() {
    return {
      log_as : "",
      isLoading : true,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
      },
      // add Modal
      questionInput:"",
      answerInput:"",
      categoryQuestionInput:"",
      // edit Modal
      editquestionInput:"",
      editanswerInput:"",
      editQuestionCategory:"",
      editId:"",
      // others
      listData:[],
      showAddPublicationModal: false,
      showEditPublicationModal: false,
      selectedRows: [],
      // category
      provideSelect: {
        question:[],
      },
    };
  },
  methods: {
    changeSizePage() {
      this.getData()
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData()
    },
    getTypeQuestion() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`faq-types` , {headers})
          .then(({data}) => {
            if (data.data == null) {
              this.provideSelect.question = []
            } else {
              this.provideSelect.question = data.data
            }
          })
          .catch((err) => {
            console.log(err);
        })
    },
    getData() {
      // this.isLoading = true
        // let headers = {
        //   'ngrok-skip-browser-warning':'true',
        //   Authorization: `Bearer ${localStorage.getItem('token')}`
        // }

        // headers['Access-Control-Allow-Origin'] = '*';
        // headers['Access-Control-Allow-Headers'] = '*';

        // API.get(`faqs?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
        API.get(`faqs?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}`)
          .then(({data}) => {
            if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.pagination.total_items
            }
              this.isLoading = false
          })
          .catch((err) => {
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
            this.isLoading = false
        })
    },
    handleAdd() {
      this.showAddPublicationModal = true
    },
    handleUpdate(data) {
      this.showEditPublicationModal = true
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        API.get(`faqs/${data.id}` , {headers})
          .then(({data:content}) => {
            console.log(content.data);
              this.editId = content.data.id
              this.editQuestionCategory = content.data.type_id
              this.editanswerInput = content.data.answer
              this.editquestionInput = content.data.question
            })
          .catch((err) => {
            console.log(err);
      })
    },
    doDelete(data) {
      Swal.fire({
        title: 'Hapus Data ini ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          API.delete(`faqs/${data.id}` , {headers})
          .then(({data:content}) => {
            Swal.fire(
            'Deleted!',
            '',
            'success'
          )

          this.getData()
          })
          .catch((err) => {
            console.log(err);
          })

          
        }
      })
    },
    doAdd() {
      if (!this.questionInput || !this.answerInput || !this.categoryQuestionInput) {
        Swal.fire(
            'Data anda tidak lengkap!',
            'Wajib mengisi semua data.',
            'error'
        )

      } else {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
  
        let payload = {
          answer: this.answerInput,
          type_id: this.categoryQuestionInput,
          question: this.questionInput
        }



        Swal.fire({
        title: 'Tambahkan Data ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Tambah',
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.isConfirmed) {
          API.post(`faqs` , payload, {headers})
          .then(({data:content}) => {
            Swal.fire(
            'Data ditambahkan!',
            '',
            'success'
            )

            this.getData()

            this.questionInput = ""
            this.answerInput = ""
            this.categoryQuestionInput = ""

            this.showAddPublicationModal = false
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
            'Data gagal ditambahkan!',
            '',
            'error'
            )
        })
        }
      })
      }
    },
    doEdit() {
      if (!this.editquestionInput|| !this.editanswerInput) {
        Swal.fire(
            'Data anda tidak lengkap!',
            'Wajib mengisi semua data.',
            'error'
        )

      } else {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
  
        let payload = {
          answer: this.editanswerInput,
          type_id: this.editQuestionCategory,
          question: this.editquestionInput,
        }



        Swal.fire({
        title: 'Merubah Data ? ',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Rubah Data',
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.isConfirmed) {
          API.put(`faqs/${this.editId}` , payload, {headers})
          .then(({data:content}) => {
            Swal.fire(
            'Data berhasil dirubah!',
            '',
            'success'
            )

            this.getData()

            this.editquestionInput = ""
            this.editanswerInput = ""
            this.editQuestionCategory = ""
            this.editId = ""

            this.showEditPublicationModal = false
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(
            'Data gagal diEdit!',
            '',
            'error'
            )
        })
        }
      })
      }
    },
  },
};
</script>

